<template>
  <div class="d-flex flex-column overflow-y-auto">
    <div class="fsc-scroll overflow-y-auto flex-grow-1" :class="contentClass">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "ScrollableContent",
  props: {
    contentClass: {
      type: String,
    },
  },
};
</script>
