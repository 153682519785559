



































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Avatar from "@/components/Avatars/Avatar.vue";
import ScrollableContent from "@/components/ScrollableContent.vue";
import StudentRequestMixin from "@/mixins/Request/StudentRequestMixin";
import { mixins } from "vue-class-component";

@Component({
  components: { ScrollableContent, Avatar, FscSimpleCard },
})
export default class RegistrationInfo extends mixins(StudentRequestMixin) {
  public name = "RegistrationInfo";

  @Prop()
  public educations!: any;

  public activeTab = 0;

  public changeTab(index: number) {
    this.activeTab = index;
  }

  public daysOfTheWeek = [
    {
      id: 1,
      name: this.$t("short_days.monday"),
    },
    {
      id: 2,
      name: this.$t("short_days.tuesday"),
    },
    {
      id: 3,
      name: this.$t("short_days.wednesday"),
    },
    {
      id: 4,
      name: this.$t("short_days.thursday"),
    },
    {
      id: 5,
      name: this.$t("short_days.friday"),
    },
    {
      id: 6,
      name: this.$t("short_days.saturday"),
    },
    {
      id: 7,
      name: this.$t("short_days.sunday"),
    },
  ];

  public daysForContact: any = [];

  public calculateYears(birthYear: any) {
    let newAge = Date.now() - birthYear.getTime();
    let currentAge = new Date(newAge);
    return Math.abs(currentAge.getUTCFullYear() - 1970);
  }

  public get studentBirth(): any {
    return this.educations && this.educations.birthDate
      ? `${this.$options.filters?.formatDate(this.educations.birthDate)} (${this.calculateYears(new Date(this.educations.birthDate || 0))})`
      : null;
  }

  public get userLicenseClass(): string {
    if (this.educations.licenseClass && this.educations.licenseClass.keyNumber) {
      return `${this.educations.licenseClass.licenseClass}${this.educations.licenseClass.keyNumber} `;
    } else if (this.educations.licenseClass && !this.educations.licenseClass.keyNumber) {
      return this.educations.licenseClass.licenseClass;
    }
    return "";
  }

  public get userBranch(): string {
    return this.educations.branch ? this.educations.branch.name : "";
  }

  public get userPaymentWorkflow(): string {
    return this.educations.paymentWorkflow ? this.educations.paymentWorkflow.name : "";
  }

  public get theoryCourseName(): string {
    return this.educations.theoryCourse ? this.educations.theoryCourse.name : "";
  }

  public async openEmailForm(email: string) {
    await this.$router.push({ name: "EmailClient" });
    this.$root.$emit("open-email-form", email);
  }
  @Watch("educations", { deep: true, immediate: true })
  public onEducationsChange(education: any) {
    this.daysForContact = [];
    if (education && education.daysOfWeekForContact) {
      let daysArray: any = [];
      education.daysOfWeekForContact.map((day: any) => {
        let hasDay = this.daysOfTheWeek.filter((d: any) => d.id === day);
        hasDay.map((hasday: any) => {
          daysArray.push(hasday);
        });
      });
      this.daysForContact = daysArray;
    }
  }

  public get hasEmailConfirmed() {
    if (this.educations.emailConfirmed) {
      return true;
    }
    return false;
  }
}
